<template>
    <div>
        <Loader v-if="copyFilesLoading" />
        <st-modal
            ref='st-copy-file-modal'
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            size="xs"
            :title="$t('APPLICATION.BULK_COPY_FILE_MODAL.TITLE')"
            customClass="form-modal"
            :id="modalId"
        >
            <template #body>
                <div>
                    <div class="form-group">
                        <b-form-group>
                            <template #label v-if="files.length">
                                <b-form-checkbox
                                    v-model="allSelected"
                                    :indeterminate="indeterminate"
                                    @change="toggleAll"
                                    size="lg"
                                >
                                    {{ allSelected ? $t('GENERAL.ACTIONS.UN_SELECT_ALL') : $t('GENERAL.ACTIONS.SELECT_ALL') }}
                                </b-form-checkbox>
                            </template>
                            <b-form-checkbox-group
                                id="file_ids"
                                v-model="selectedFileIds"
                                :options="files"
                                value-field="file_id"
                                text-field="filename"
                                stacked
                                size="lg"
                            ></b-form-checkbox-group>
                        </b-form-group>
                    </div>
                    <st-button
                        variant="primary"
                        :disabled="!canSave"
                        :callback="confirmCopyFiles">
                        {{ $t('GENERAL.ACTIONS.COPY') }}
                    </st-button>
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { generateRandomId } from '@/core/helpers/globalMethods';

export default {
    name: 'ApplicationBulkCopyFilesModal',
    props: {
        files: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            copyFilesLoading: false,
            selectedFileIds: [],
            modalId: `st-modal-copy-file-${generateRandomId()}`,
            allSelected: false,
            indeterminate: false
        }
    },
    watch: {
        selectedFileIds(newValue) {
            if (newValue.length === 0) {
                this.indeterminate = false
                this.allSelected = false
            } else if (newValue.length === this.files.length) {
                this.indeterminate = false
                this.allSelected = true
            } else {
                this.indeterminate = true
                this.allSelected = false
            }
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            record: 'applications/form/record',
            rows: 'applications/form/docs',
            fileToken: 'applications/form/fileToken',
            uploadAccessToken: 'applications/form/uploadAccessToken',
        }),
        canSave() {
            return !!this.selectedFileIds.length;
        },
    },
    methods: {
        ...mapActions({
            downloadFileContent: 'shared/downloadFileContent',
            doUpload: 'applications/form/upload',
            doUpdate: 'applications/form/update',
        }),
        show() {
            this.$refs['st-copy-file-modal'].show();
        },
        hide(){
            this.$refs['st-copy-file-modal'].hide();
        },
        confirmCopyFiles() {
            this.$alert({
                type: 'warning',
                text: this.$t('APPLICATION.BULK_COPY_FILE_MODAL.CONFIRM_MSG'),
                confirmButtonText: this.$t('GENERAL.YES'),
                cancelButtonText: this.$t('GENERAL.NO'),
                hasConfirmation: true,
                confirmCallback: () => {
                    this.copyFiles();
                }
            });
        },
        async copyFiles() {
            this.copyFilesLoading = true;

            const formData = new FormData();

            const copiedFiles = this.files.filter(v => this.selectedFileIds.indexOf(v.file_id) !== -1);

            for (let i = 0; i < copiedFiles.length; i++) {
                let filename = copiedFiles[i].filename;

                //const url = `${window.VUE_APP_API_URL}/files/${copiedFiles[i].file_id}?token=${this.fileToken}`;
                const url = `${window.VUE_APP_API_URL}/files/${copiedFiles[i].file_id}`;
                const fileContent = await this.downloadFileContent({url, token: this.fileToken});

                let extension = ".pdf";
                if (filename.lastIndexOf('.') !== -1) {
                    extension = filename.slice(filename.lastIndexOf('.'));
                    filename = filename.slice(0, filename.lastIndexOf('.'));
                }

                const prefix = `${this.$t('GENERAL.COPY_PREFIX')}_`;
                let newFileName = `${prefix}${filename.replaceAll(' ', '_')}${extension}`;

                let file = new File([fileContent], newFileName, { type: fileContent.type })

                formData.append('files', file);
            }

            const uploadedFiles = await this.doUpload({
                files: formData,
                uploadAccessToken: this.uploadAccessToken,
            });

            const clonedFiles = uploadedFiles.map((el) => ({
                file_id: el.id,
                filename: el.initialFilename,
                created_date: el.created_date,
                file_type: 'output',
                created_by: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                is_public: false
            }));

            const payload = {
                id: this.record.id,
                loadStaff: true,
                values: {
                    files: [
                        ...this.rows,
                        ...clonedFiles,
                    ],
                },
            };

            await this.doUpdate(payload);

            this.copyFilesLoading = false;

            this.$emit('cloned');
            this.hide();
        },
        toggleAll(checked) {
            this.selectedFileIds = checked ? this.files.map((el) => el.file_id) : [];
        },
    },
    mounted() {},
}
</script>
